import React from 'react';
import PropTypes from 'prop-types';
import { Grid, Table, TableBody } from '@mui/material';
import {
  Chart as ChartJS, LinearScale, CategoryScale, BarElement, PointElement, Title,
  LineElement, Legend, Tooltip, LineController, BarController,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import currencyAmount from '../../utils/currencyAmount';
import {
  TableComponent, TableColumnHeaders, TableHeader, TableColumnRowText, TableColumnRows
} from '../shared/tableGlobalStyle.styles';
import {
  PaperGrid, GraphTypo, ExportButton, LineBox, DataBox
} from './customGraphData.styles';

const CustomGraphData = ({
  filter, displayType, heading, handleDownloadCSV, data, options, headers,
  tableData, isMultipleValuesRow
}) => {
  ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    Title,
    LineController,
    BarController
  );

  const returnHeaders = () => headers.map(({ name, width }) => (
    <TableHeader key={name} style={{ width }}>{name}</TableHeader>
  ));

  const renderBodyCells = ({ period, value }) => {
    const cells = [{ name: period, width: '50%' }, { name: `₦ ${currencyAmount(value)}`, width: '50%' }];
    return cells.map(({ name, width }) => (
      <TableColumnRowText key={name} style={{ width }}>{name || '-'}</TableColumnRowText>
    ));
  };

  const renderMultipleBodyCells = ({ period, value }) => {
    const cells = [{ name: period, width: '6rem' }];
    value?.map((_value) => cells.push({ name: currencyAmount(_value), width: '9rem' }));
    return cells.map(({ name, width }) => (
      <TableColumnRowText key={name} style={{ width }}>{name || '-'}</TableColumnRowText>
    ));
  };
  console.log('data', data)

  return (
    <PaperGrid elevation={2} filter={filter === 'week' || isMultipleValuesRow}>
      <Grid item container justifyContent="space-between" alignItems="center">
        <GraphTypo>{heading}</GraphTypo>
        <ExportButton onClick={() => handleDownloadCSV('orderValue')} data-testid="orderValueExport">
          Export CSV
        </ExportButton>
      </Grid>
      {displayType === 'Graph' ? (
        <LineBox width={filter === 'week' ? '70rem' : '100%'}>
          <Chart data={data} options={options} />
        </LineBox>
      ) : (
        <DataBox>
          <TableComponent>
            <Table>
              <TableColumnHeaders>
                {returnHeaders()}
              </TableColumnHeaders>
              <TableBody>
                {tableData?.map((row) => (
                  <TableColumnRows key={row.name}>
                    {!isMultipleValuesRow ? renderBodyCells(row) : renderMultipleBodyCells(row)}
                  </TableColumnRows>
                ))}
              </TableBody>
            </Table>
          </TableComponent>
        </DataBox>
      )}
    </PaperGrid>
  );
};

CustomGraphData.propTypes = {
  filter: PropTypes.string,
  displayType: PropTypes.string,
  heading: PropTypes.string,
  handleDownloadCSV: PropTypes.func.isRequired,
  data: PropTypes.shape(Object).isRequired,
  options: PropTypes.shape(Object).isRequired,
  headers: PropTypes.shape(Object).isRequired,
  tableData: PropTypes.shape(Array).isRequired,
  isMultipleValuesRow: PropTypes.shape(Array),
};

CustomGraphData.defaultProps = {
  filter: '',
  displayType: '',
  heading: '',
  isMultipleValuesRow: false,
};

export default CustomGraphData;
